import { useState } from "react";

const MultipleChoiceCard = ({ changeInfo }) => {
    const [option, setOption] = useState(null);
    return (
        <div className="question-card">
            <div
                className="flex align-items-center"
                style={{ marginBottom: 16, padding: "15px 0px" }}
            >
                <div
                    className="circle flex justify-content-center align-items-center"
                    style={{ marginRight: 20 }}
                >
                    6
                </div>
                <p>¿Cómo te enteraste sobre este servicio?</p>
            </div>

            <div
                className="flex justify-content-center align-items-center justify-content-start"
                style={{
                    backgroundColor: "transparent",
                    padding: 0,
                    marginBottom: 20,
                }}
            >
                <fieldset id="group">
                    <div className="flex align-items-center mb-1">
                        <input
                            type="radio"
                            value={0}
                            name="group"
                            onChange={(e) => {
                                changeInfo("foundBy", e.target.value);
                                setOption(e.target.value);
                            }}
                        />
                        <label className="caption font-gray">
                            Redes sociales
                        </label>
                    </div>
                    <div className="flex align-items-center mb-1">
                        <input
                            type="radio"
                            value={1}
                            name="group"
                            onChange={(e) => {
                                changeInfo("foundBy", e.target.value);
                                setOption(e.target.value);
                            }}
                        />
                        <label className="caption font-gray">
                            Familiar o amigo
                        </label>
                    </div>
                    <div className="flex align-items-center mb-1">
                        <input
                            type="radio"
                            value={2}
                            name="group"
                            onChange={(e) => {
                                changeInfo("foundBy", e.target.value);
                                setOption(e.target.value);
                            }}
                        />
                        <label className="caption font-gray">
                            Charlas en mi colegio o comunidad
                        </label>
                    </div>
                    <div className="flex align-items-center mb-1">
                        <input
                            type="radio"
                            value={3}
                            name="group"
                            onChange={(e) => {
                                changeInfo("foundBy", e.target.value);
                                setOption(e.target.value);
                            }}
                        />
                        <label className="caption font-gray">
                            Agente comunitario
                        </label>
                    </div>
                    <div className="flex align-items-center mb-1">
                        <input
                            type="radio"
                            value={4}
                            name="group"
                            onChange={(e) => {
                                changeInfo("foundBy", e.target.value);
                                setOption(e.target.value);
                            }}
                        />
                        <label className="caption font-gray">
                            Referido por un profesional de la USF
                        </label>
                    </div>
                    <div className="flex align-items-center mb-1">
                        <input
                            type="radio"
                            value={5}
                            name="group"
                            onChange={(e) => {
                                changeInfo("foundBy", e.target.value);
                                setOption(e.target.value);
                            }}
                        />
                        <label className="caption font-gray">
                            Otros (Ej: radio, TV, etc.)
                        </label>
                    </div>
                </fieldset>
            </div>
            {option === "5" ? (
                <div className="flex align-items-center">
                    <p
                        className="body-1 font-gray"
                        style={{ marginRight: 10, width: 230 }}
                    >
                        ¿Cómo te enteraste?:
                    </p>
                    <input
                        type="text"
                        className="question-input"
                        onChange={(e) =>
                            changeInfo("foundByOther", e.target.value)
                        }
                    />
                </div>
            ) : null}
        </div>
    );
};

export default MultipleChoiceCard;
