import { useEffect, useState } from "react";
import Header from "../components/Header";
import logo from "../assets/logo.jpg";
import QuestionCard from "../components/QuestionCard";
import MultipleChoiceCard from "../components/MultipleChoiceCard";
import CommentCard from "../components/CommentCard";
import { createRound, getQuestions, getHospitals } from "../services/api";
import Question2OptionCard from "../components/Question2OptionCard";
import Spinner from "../components/Spinner";
import HospitalModal from "../components/HospitalModal";
import AgeCard from "../components/AgeCard";
import PhoneModal from "../components/PhoneModal";
import PasswordModal from "../components/PasswordModal";

const InitialScreen = () => {
    const [steps, setSteps] = useState(0);
    const [govId, setGovId] = useState("");
    const [questions, setQuestions] = useState([]);
    const [info, setInfo] = useState({
        comments: "",
        foundByOther: "",
        date: null,
        foundBy: null,
        age: null,
        answers: [],
        firstTime: null,
    });

    const [savedAnswers, setSavedAnswers] = useState({});
    const [loading, setLoading] = useState(false);
    const [hospitals, setHospitals] = useState([]);
    const [selectedHospital, setSelectedHospital] = useState(
        JSON.parse(localStorage.getItem("hospital"))
    );

    const [showHospitalModal, setShowHospitalModal] = useState(false);
    const [showPhoneModal, setShowPhoneModal] = useState(false);

    const [showPasswordModal, setShowPasswordModal] = useState(false);

    useEffect(() => {
        getQuestions().then((res) => {
            setQuestions(res);
        });
        getHospitals().then((res) => {
            setHospitals(res.filter((e) => e.availableForSurvey === true));
            if (selectedHospital === null) {
                setShowHospitalModal(true);
            }
        });
    }, []);

    useEffect(() => {
        setSelectedHospital(JSON.parse(localStorage.getItem("hospital")));
    }, [showHospitalModal]);

    const addAnswer = (answer) => {
        let prevAnswers = [...info.answers];
        const index = prevAnswers.findIndex(
            (el) => el.question === answer.question
        );
        if (index >= 0) {
            prevAnswers[index] = answer;
            setInfo({ ...info, answers: prevAnswers });
        } else {
            setInfo({ ...info, answers: [...info.answers, answer] });
        }
    };

    const changeInfo = (key, value) => {
        setInfo({ ...info, [key]: value });
    };

    const disableInput = () => {
        return govId.length < 6;
    };

    const goToStart = () => {
        setSteps(0);
        setSavedAnswers({});
        setGovId("");
        setInfo({
            comments: "",
            foundByOther: "",
            date: null,
            foundBy: null,
            answers: [],
        });
    };

    const sendAnswers = () => {
        setLoading(true);
        const date = new Date();
        const data = {
            govId: govId,
            hospital: selectedHospital.id,
            comments: info.comments,
            foundBy: info.foundBy,
            foundByOther: info.foundByOther,
            answers: info.answers,
            age: info.age,
            date: `${date.getFullYear()}-${
                date.getMonth() + 1
            }-${date.getDate()}`,
        };

        createRound(data)
            .then((res) => {
                setSteps(steps + 1);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                alert(err.data.nonFieldErrors[0]);
            });
    };

    if (steps === 0) {
        return (
            <div
                className="flex-container"
                style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {showHospitalModal && (
                    <HospitalModal
                        setIsOpen={setShowHospitalModal}
                        hospitals={hospitals}
                    />
                )}
                {showPhoneModal && <PhoneModal setIsOpen={setShowPhoneModal} />}
                {showPasswordModal && (
                    <PasswordModal
                        setIsOpen={setShowPasswordModal}
                        setShowHospitalModal={setShowHospitalModal}
                    />
                )}
                <img src={logo} className="initial-logo" alt="logo" />
                <div className="flex flex-column">
                    <button
                        type="button"
                        className="button button-red mb-1"
                        onClick={() => setSteps(steps + 1)}
                    >
                        Responder In Situ
                    </button>
                    {/* <button
                        type="button"
                        className="button button-red mb-1"
                        onClick={() => setShowPhoneModal(true)}
                        style={{
                            backgroundColor: "white",
                            color: "#d91a28",
                            borderColor: "#d91a28",
                            border: 1,
                            borderStyle: "solid",
                        }}
                    >
                        Responder vía Whatsapp
                    </button> */}
                    <button
                        className="small"
                        style={{
                            textDecoration: "underline",
                            cursor: "pointer",
                            border: "none",
                            backgroundColor: "transparent",
                        }}
                        onClick={() => setShowPasswordModal(true)}
                    >
                        Cambiar hospital
                    </button>
                </div>
            </div>
        );
    } else if (steps === 1) {
        return (
            <div style={{ padding: "30px 40px" }}>
                <Header
                    goToStart={goToStart}
                    showButton={true}
                    hospital={selectedHospital}
                />
                <div className="gov-id-input-container flex justify-content-center align-items-center">
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 100,
                        }}
                    >
                        <label className="heading font-color-blue">
                            Ingresa tu número de cédula
                        </label>
                        <input
                            className="gov-id-input"
                            placeholder="2098654"
                            type="number"
                            maxLength="7"
                            pattern="\d*"
                            onChange={(e) => setGovId(e.target.value)}
                        />
                        <div className="flex justify-content-between align-items-center">
                            <label
                                className="body-1 font-gray"
                                style={{ maxWidth: 450 }}
                            >
                                * Esta encuesta es anónima. El número de cédula
                                no se utilizará para identificar a ningún
                                paciente.
                            </label>
                            <button
                                type="button"
                                className="button button-red flex align-items-center"
                                onClick={() => setSteps(steps + 1)}
                                disabled={disableInput()}
                            >
                                Empezar
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ marginLeft: 5 }}
                                >
                                    <path
                                        d="M5 12H19"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M12 5L19 12L12 19"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else if (steps === 2) {
        return (
            <div
                className="flex-container"
                style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <img src={logo} className="initial-logo" alt="logo" />
                <div className="question-card">
                    <div
                        className="flex align-items-center"
                        style={{
                            marginBottom: 16,
                            padding: "15px 0px",
                            fontSize: 16,
                        }}
                    >
                        <p>¿Es la primera vez que nos visitas?</p>
                    </div>

                    <div
                        className="flex justify-content-center align-items-center justify-content-start"
                        style={{
                            backgroundColor: "transparent",
                            padding: 0,
                            marginBottom: 20,
                        }}
                    >
                        <fieldset
                            id="group"
                            style={{ display: "flex", columnGap: 100 }}
                        >
                            <div className="flex align-items-center mb-1">
                                <input
                                    type="radio"
                                    value={0}
                                    name="group"
                                    onChange={(e) =>
                                        changeInfo("firstTime", true)
                                    }
                                />
                                <label className="caption font-gray">Sí</label>
                            </div>
                            <div className="flex align-items-center mb-1">
                                <input
                                    type="radio"
                                    value={1}
                                    name="group"
                                    onChange={(e) =>
                                        changeInfo("firstTime", false)
                                    }
                                />
                                <label className="caption font-gray">No</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <button
                    type="button"
                    className="button button-red mb-1"
                    onClick={() => setSteps(steps + 1)}
                >
                    Empezar encuesta
                </button>
            </div>
        );
    } else if (steps === 3) {
        return (
            <div className="flex flex-column " style={{ padding: "30px 40px" }}>
                <Header
                    goToStart={goToStart}
                    hospital={selectedHospital}
                    showButton={true}
                />
                <div className="flex flex-column justify-content-center align-items-center">
                    <h3
                        className="heading-big font-navy"
                        style={{ marginBottom: 20, textAlign: "center" }}
                    >
                        Encuesta de satisfacción
                    </h3>
                    <p
                        className="body-1 font-gray"
                        style={{
                            maxWidth: 400,
                            textAlign: "center",
                            marginBottom: 50,
                        }}
                    >
                        Qué bueno que viniste, nos gustaría conocer tu opinión.
                        Esta encuesta es 100% anónima. ¡Queremos mejorar para
                        vos!
                    </p>
                    {questions.map((question, index) => {
                        if (question.possibleAnswers.length === 3) {
                            return (
                                <QuestionCard
                                    data={question}
                                    index={index}
                                    key={index}
                                    addAnswer={addAnswer}
                                    savedAnswers={savedAnswers}
                                    setSavedAnswers={setSavedAnswers}
                                />
                            );
                        } else if (question.possibleAnswers.length === 2) {
                            return (
                                <Question2OptionCard
                                    data={question}
                                    index={index}
                                    key={index}
                                    addAnswer={addAnswer}
                                    savedAnswers={savedAnswers}
                                    setSavedAnswers={setSavedAnswers}
                                />
                            );
                        }
                    })}
                    {info.firstTime ? (
                        <MultipleChoiceCard changeInfo={changeInfo} />
                    ) : null}
                    <AgeCard
                        changeInfo={changeInfo}
                        index={!info.firstTime ? 6 : 7}
                    />
                    <CommentCard changeInfo={changeInfo} />
                    <div
                        className="flex justify-content-between"
                        style={{ maxWidth: 700, width: "100%" }}
                    >
                        <button
                            className="button button-light-blue flex align-items-center"
                            disabled
                        >
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ marginRight: 5 }}
                            >
                                <path
                                    d="M19 12L5 12"
                                    stroke="#7e7e7e"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M12 19L5 12L12 5"
                                    stroke="#7e7e7e"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            Anterior
                        </button>
                        {loading ? (
                            <Spinner />
                        ) : (
                            <button
                                className="button button-blue flex align-items-center"
                                onClick={sendAnswers}
                                disabled={
                                    (info.foundBy === null &&
                                        info.foundByOther === "" &&
                                        info.firstTime) ||
                                    info.answers.length !== 5
                                }
                            >
                                Finalizar
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    style={{ marginLeft: 5 }}
                                >
                                    <path
                                        d="M5 12H19"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M12 5L19 12L12 19"
                                        stroke="white"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        )}
                        {/* <button
                            className="button button-blue flex align-items-center"
                            onClick={() => setSteps(steps + 1)}
                            disabled={info.answers.length !== 6}
                        >
                            Siguiente
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                style={{ marginLeft: 5 }}
                            >
                                <path
                                    d="M5 12H19"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                                <path
                                    d="M12 5L19 12L12 19"
                                    stroke="white"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button> */}
                    </div>
                </div>
            </div>
        );
    } else if (steps === 4) {
        return (
            <div className="flex flex-column " style={{ padding: "30px 40px" }}>
                <Header
                    goToStart={goToStart}
                    hospital={selectedHospital}
                    showButton={false}
                />
                <div className="flex flex-column justify-content-center align-items-center">
                    <svg
                        width="226"
                        height="124"
                        viewBox="0 0 226 124"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="mb-4"
                    >
                        <circle cx="130.5" cy="61.5" r="57.5" fill="#133DC9" />
                        <path
                            d="M129.5 85.25C142.617 85.25 153.25 74.6168 153.25 61.5C153.25 48.3832 142.617 37.75 129.5 37.75C116.383 37.75 105.75 48.3832 105.75 61.5C105.75 74.6168 116.383 85.25 129.5 85.25Z"
                            stroke="white"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M120 66.25C120 66.25 123.562 71 129.5 71C135.438 71 139 66.25 139 66.25"
                            stroke="white"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M122.375 54.375H122.399"
                            stroke="white"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <path
                            d="M136.625 54.375H136.649"
                            stroke="white"
                            strokeWidth="4"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                        <circle cx="211" cy="15" r="15" fill="#DBEEFF" />
                        <circle cx="10.5" cy="70.5" r="10.5" fill="#DBEEFF" />
                        <path
                            opacity="0.5"
                            d="M56 119C51.2261 119 46.6477 117.104 43.2721 113.728C39.8964 110.352 38 105.774 38 101C38 96.2261 39.8964 91.6477 43.2721 88.2721C46.6477 84.8964 51.2261 83 56 83"
                            stroke="#FFA7A7"
                            strokeWidth="10"
                        />
                    </svg>
                    <h4 className="heading mb-1" style={{ color: "#133DC9" }}>
                        ¡Gracias por participar de la encuesta!
                    </h4>
                    <p className="body-1 mb-4">
                        Tu opinión es valiosa para el mejoramiento de nuestros
                        servicios.
                    </p>
                    <button
                        className="button button-light-blue flex align-items-center"
                        style={{ color: "#243672" }}
                        onClick={goToStart}
                    >
                        Volver al inicio
                    </button>
                </div>
            </div>
        );
    }
};

export default InitialScreen;
