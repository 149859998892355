const AgeCard = ({ changeInfo, index }) => {
    return (
        <div className="question-card">
            <div
                className="flex align-items-center"
                style={{ marginBottom: 16, padding: "15px 0px" }}
            >
                <div
                    className="circle flex justify-content-center align-items-center"
                    style={{ marginRight: 20 }}
                >
                    {index}
                </div>
                <p>¿Cuántos años tenes?</p>
            </div>

            <div
                className="flex justify-content-center align-items-center justify-content-start"
                style={{
                    backgroundColor: "transparent",
                    padding: 0,
                    marginBottom: 20,
                }}
            >
                <select
                    style={{
                        padding: 15,
                        border: 2,
                        borderColor: "#dbeeff",
                        borderStyle: "solid",
                        width: "100%",
                        borderRadius: 5,
                        backgroundColor: "white",
                        outline: "none",
                    }}
                    onChange={(e) => changeInfo("age", e.target.value)}
                >
                    <option value="" className="body-1" disabled selected>
                        Seleccionar
                    </option>
                    <option value={10} className="body-1">
                        10
                    </option>
                    <option value={11} className="body-1">
                        11
                    </option>
                    <option value={12} className="body-1">
                        12
                    </option>
                    <option value={13} className="body-1">
                        13
                    </option>
                    <option value={14} className="body-1">
                        14
                    </option>
                    <option value={15} className="body-1">
                        15
                    </option>
                    <option value={16} className="body-1">
                        16
                    </option>
                    <option value={17} className="body-1">
                        17
                    </option>
                    <option value={18} className="body-1">
                        18
                    </option>
                    <option value={19} className="body-1">
                        19
                    </option>
                </select>
            </div>
        </div>
    );
};

export default AgeCard;
