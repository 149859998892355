import React, { useState } from "react";
import "../modal.css";
import { savePhone } from "../services/api";
import Spinner from "./Spinner";

const PhoneModal = ({ setIsOpen }) => {
    const [phone, setPhone] = useState("");
    const [loading, setLoading] = useState(false);

    const handleSubmit = () => {
        const regex = /^((\+595|0)9([6-9][1-6])\d{6})$/;
        console.log(phone.match(regex))
        if (phone.match(regex) === null){
            alert("Ingresaste un número de teléfono no válido.")
        }else{
            setLoading(true);
            const date = new Date();
            const data = {
                phone: phone,
                date: `${date.getFullYear()}-${
                    date.getMonth() + 1
                }-${date.getDate()}`,
            };
            savePhone(data)
                .then((res) => {
                    setLoading(false);
                    setIsOpen(false);
                })
                .catch((err) => console.log(err));
        }
       
    };

    return (
        <>
            <div className="darkBG" onClick={() => setIsOpen(false)} />
            <div className="centered">
                <div className="modal">
                    <div className="modalContent">
                        <h5 className="heading mb-1">Encuesta vía whatsapp</h5>
                        <p className="caption mb-1">
                            Ingrese el número de teléfono de la paciente
                        </p>
                        <p className="small mb-3">
                            Ejemplo: 0981546223
                        </p>
                        <input
                            type="text"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            className="mb-2"
                            style={{
                                padding: 10,
                                border: "2px #000000 solid",
                                borderRadius: 5,
                            }}
                        />
                    </div>
                    <div className="modalActions">
                        <div className="actionsContainer">
                            {loading ? (
                                <Spinner />
                            ) : (
                                <button
                                    className="button button-blue flex"
                                    disabled={phone === ""}
                                    onClick={handleSubmit}
                                >
                                    Aceptar
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PhoneModal;
