import logo from "../assets/logo.jpg";

const Header = ({ goToStart, showButton, hospital }) => {
    return (
        <div
            className="flex justify-content-between align-items-center mb-4"
            style={{
                width: "100%",
                height: 60,
            }}
        >
            <img src={logo} style={{ maxWidth: 160 }} alt="logo" />
            <p className="caption">{hospital.name}</p>
            {showButton ? (
                <button
                    className="flex justify-content-center align-items-center"
                    style={{
                        backgroundColor: "transparent",
                        border: "none",
                        cursor: "pointer",
                    }}
                    onClick={goToStart}
                >
                    <span className="button exit-button">Salir</span>
                    <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M6.75 15.75H3.75C3.35218 15.75 2.97064 15.592 2.68934 15.3107C2.40804 15.0294 2.25 14.6478 2.25 14.25V3.75C2.25 3.35218 2.40804 2.97064 2.68934 2.68934C2.97064 2.40804 3.35218 2.25 3.75 2.25H6.75"
                            stroke="#D91A28"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M12 12.75L15.75 9L12 5.25"
                            stroke="#D91A28"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M15.75 9H6.75"
                            stroke="#D91A28"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </button>
            ) : null}
        </div>
    );
};

export default Header;
