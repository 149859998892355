import axios from "axios";

const errorCodes = {
    badRequest: "badRequest",
    unauthorized: "unauthorized",
    forbidden: "forbidden",
    notFound: "notFound",
    serverError: "serverError",
    unexpected: "unexpected",
    invalidCredentials: "invalidCredentials",
};

const handleError = (error) => {
    if (error.response.status === 400)
        return Promise.reject({
            code: errorCodes.badRequest,
            data: error.response?.data,
        });
    if (error.response.status === 401)
        return Promise.reject({
            code: errorCodes.unauthorized,
            data: error.response?.data,
        });
    if (error.response.status === 403)
        return Promise.reject({
            code: errorCodes.forbidden,
            data: error.response?.data,
        });
    if (error.response.status === 404)
        return Promise.reject({
            code: errorCodes.notFound,
            data: error.response?.data,
        });
    if (500 <= error.response.status <= 599)
        return Promise.reject({
            code: errorCodes.serverError,
            data: error.response?.data,
        });
    return Promise.reject({
        code: errorCodes.unexpected,
        data: error.response?.data,
    });
};

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        Accept: "application/json",
    },
});

export const getQuestions = async () => {
    try {
        const response = await api.get("/questions/");
        return Promise.resolve(response.data);
    } catch (error) {
        return handleError(error);
    }
};

export const createRound = async (data) => {
    try {
        const response = await api.post("/rounds/", data);
        return Promise.resolve(response.data);
    } catch (error) {
        return handleError(error);
    }
};

export const getHospitals = async () => {
    try {
        const response = await api.get("/hospitals/all/");
        return Promise.resolve(response.data);
    } catch (error) {
        return handleError(error);
    }
};

export const savePhone = async (data) => {
    try {
        const response = await api.post("/whatsapp-messages/", data);
        return Promise.resolve(response.data);
    } catch (error) {
        return handleError(error);
    }
};

export const checkPassword = async (data) => {
    try {
        const response = await api.post("/check-password/", data);
        return Promise.resolve(response.data);
    } catch (error) {
        return handleError(error);
    }
};
