import React, { useEffect } from "react";
import { useState } from "react";
import "../stepper.css";

const colors = {
    red: "#D91A28",
    yellow: "#ECBE1D",
    green: "#0BC86D",
};

const QuestionCard = ({
    data,
    index,
    addAnswer,
    savedAnswers,
    setSavedAnswers,
}) => {
    const [selected, setSelected] = useState(null);
    const [notApply, setNotApply] = useState(false);
    const [other, setOther] = useState("");

    const changeSelction = (val, answer) => {
        setSelected(val);
        addAnswer({
            question: data.id,
            answer: answer.id,
            value: answer.value,
            other: other,
        });
        setSavedAnswers({ ...savedAnswers, [index]: answer });
    };

    const changeText = (val) => {
        setOther(val);
        addAnswer({
            question: data.id,
            answer: null,
            value: null,
            other: val,
        });
        setSavedAnswers({ ...savedAnswers, [index]: val });
    };

    useEffect(() => {
        if (savedAnswers.hasOwnProperty(index)) {
            if (savedAnswers[index].hasOwnProperty("value")) {
                if (savedAnswers[index].value === 0) {
                    setNotApply(true);
                }
                setSelected(savedAnswers[index].value);
            } else {
                setOther(savedAnswers[index]);
            }
        }
    }, []);

    return (
        <div className="question-card">
            <div
                className="flex align-items-center"
                style={{ marginBottom: 16, padding: "15px 0px" }}
            >
                <div
                    className="circle flex justify-content-center align-items-center"
                    style={{ marginRight: 20 }}
                >
                    {index + 1}
                </div>
                <p>{data.text}</p>
            </div>
            {data.hasSpecialAnswer ? (
                <div
                    className="flex align-items-center"
                    style={{ marginBottom: 20 }}
                >
                    <input
                        id={`checkbox${data.id}`}
                        type="checkbox"
                        className="question-check"
                        style={{ marginRight: 20 }}
                        value={notApply}
                        onChange={() => {
                            setNotApply(!notApply);
                            changeSelction(null, data.specialAnswer);
                        }}
                        checked={notApply}
                    />
                    <label
                        className="body-1 font-gray"
                        for={`checkbox${data.id}`}
                    >
                        {data.specialAnswer.text}
                    </label>
                </div>
            ) : null}
            <div className="stepper-container flex justify-content-center align-items-center">
                <div className="flex flex-column justify-content-center align-items-center circle-container">
                    <button
                        className="circle-1"
                        disabled={notApply}
                        onClick={() =>
                            changeSelction(1, data.possibleAnswers[0])
                        }
                        style={
                            selected === 1
                                ? { backgroundColor: colors.red }
                                : null
                        }
                    ></button>
                    <p className="small">{data.possibleAnswers[0].text}</p>
                </div>
                <div className="line"></div>
                <div className="flex flex-column justify-content-center align-items-center circle-container">
                    <button
                        className="circle-1"
                        disabled={notApply}
                        onClick={() =>
                            changeSelction(2, data.possibleAnswers[1])
                        }
                        style={
                            selected === 2
                                ? { backgroundColor: colors.yellow }
                                : null
                        }
                    ></button>
                    <p className="small">{data.possibleAnswers[1].text}</p>
                </div>
                <div className="line"></div>
                <div className="flex flex-column justify-content-center align-items-center circle-container">
                    <button
                        className="circle-1"
                        disabled={notApply}
                        onClick={() =>
                            changeSelction(3, data.possibleAnswers[2])
                        }
                        style={
                            selected === 3
                                ? { backgroundColor: colors.green }
                                : null
                        }
                    ></button>
                    <p className="small">{data.possibleAnswers[2].text}</p>
                </div>
            </div>
            <div className="flex align-items-center">
                <p className="body-1 font-gray" style={{ marginRight: 10 }}>
                    Comentarios:
                </p>
                <input
                    type="text"
                    className="question-input"
                    onChange={(e) => changeText(e.target.value)}
                    value={other}
                />
            </div>
        </div>
    );
};

export default QuestionCard;
