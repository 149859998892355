const CommentCard = ({ changeInfo }) => {
    return (
        <div className="question-card">
            <h5 className="body-2 mb-4">Comentarios y sugerencias</h5>
            <input
                type="text"
                className="question-input"
                onChange={(e) => changeInfo("comments", e.target.value)}
            />
        </div>
    );
};

export default CommentCard;
