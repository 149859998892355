import React, { useState } from "react";
import "../modal.css";
import { checkPassword } from "../services/api";

const PasswordModal = ({ setIsOpen,setShowHospitalModal }) => {
    const [password, setPassword] = useState("")
    const [error, setError] = useState("")

    const handleSubmit = () => {
        setError("")
        checkPassword({password}).then(res => {
            setIsOpen(false)
            setShowHospitalModal(true)
        }).catch(err =>setError(err.data.error))
    }
    return (
        <>
            <div className="darkBG" />
            <div className="centered">
                <div className="modal" style={{maxWidth: 400}}>
                    <div className="modalContent">
                        <h5 className="heading mb-1">Ingrese la contraseña</h5>
                        <p className="caption mb-3">
                            Para cambiar el hospital al que está vinculada esta encuesta debe ingresar la contraseña
                        </p>
                        <input
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                           
                            className="mb-2"
                            style={{
                                padding: 10,
                                border: "2px #000000 solid",
                                borderRadius: 5,
                            }}
                        />
                         {error !== "" ? <p style={{color:"red"}}>{error}</p>:null}
                    </div>
                    <div className="modalActions">
                        <div style={{display: "flex", alignItems: "center", justifyContent: "center", gap: 10}}>
                            <button
                                className="button button-blue flex "
                               onClick={handleSubmit}
                            >
                                Aceptar
                            </button>
                            <button
                                className="button button-light-blue flex"
                                onClick={() => setIsOpen(false)}
                               
                            >
                                Cancelar
                            </button>
                        </div>
                       
                    </div>
                   
                </div>
            </div>
        </>
    );
};

export default PasswordModal;
