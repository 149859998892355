import React, { useState } from "react";
import "../modal.css";
const Modal = ({ setIsOpen, hospitals }) => {
    const [selected, setSelected] = useState(null);

    const selectHospital = () => {
        const hospital = hospitals.find(
            (el) => parseInt(el.id) === parseInt(selected)
        );
        if (hospital !== undefined) {
            localStorage.setItem("hospital", JSON.stringify(hospital));
            setIsOpen(false);
        }
    };
    return (
        <>
            <div className="darkBG" />
            <div className="centered">
                <div className="modal">
                    <div className="modalContent">
                        <h5 className="heading mb-1">Seleccionar hospital</h5>
                        <p className="caption mb-3">
                            Seleccione el hospital al que estará vinculada la
                            encuesta
                        </p>
                        <select
                            style={{
                                padding: 5,
                                border: 2,
                            }}
                            className="mb-4"
                            onChange={(e) => setSelected(e.target.value)}
                        >
                            <option
                                value=""
                                className="body-1"
                                disabled
                                selected
                            >
                                Seleccionar
                            </option>
                            {hospitals.map((h) => (
                                <option
                                    value={h.id}
                                    key={h.id}
                                    className="body-1"
                                >
                                    {h.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="modalActions">
                        <div className="actionsContainer">
                            <button
                                className="button button-blue flex "
                                onClick={selectHospital}
                            >
                                Aceptar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;
